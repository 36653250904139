import axios from 'axios';
// Helper
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { UserInfo, UserTrii } from '@trii/types/dist/Users';

const fetchUserInfo = async (jwtToken: string, URL: string): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserTrii>(URL, config);

  return response.data;
};

const fetchUserById = async (
  jwtToken: string,
  URL: string,
  userId: string
): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserTrii>(`${URL}/usersInfo/${userId}`, config);

  return response.data;
}
const fetchUsers = async (
  jwtToken: string,
  URL_USERS: string
): Promise<UserInfo[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserInfo[]>(
    `${URL_USERS}/users`,
    config
  );

  return response.data;
};

const userSliceService = {
  fetchUserInfo,
  fetchUserById,
  fetchUsers,
};

export default userSliceService;
