import {
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

const checkSessionValidity = async (
  user: CognitoUser,
  session: CognitoUserSession
): Promise<CognitoUserSession> => {
  if (session.isValid()) {
    return session;
  } else {
    const refreshedSession = await refreshSession(user, session);
    return refreshedSession;
  }
};

const refreshSession = async (
  user: CognitoUser,
  session: CognitoUserSession
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: session.getRefreshToken().getToken(),
    });

    return new Promise((resolve, reject) => {
      user.refreshSession(refreshToken, (err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  });
};

const sessionHelper = { checkSessionValidity };

export default sessionHelper;
