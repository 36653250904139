import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next} from 'react-i18next'


//Falta averiguar funcionamiento
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'ES',
    debug: true,
    // detection: {
    //     order: [ 'cookie','queryString'],
    //     cache: ['cookie']
    // },
    interpolation: {
        escapeValue: false
    },
    backend: {
        loadPath: "/agent/debtcollection/locales/{{lng}}/{{ns}}.json",
    },
})
i18n.on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);});


export default i18n;