import { useEffect, useMemo } from 'react';
import Router from './router';
// Types
import RootProps from 'types/RootProps';
// Theme
import { createTheme, ThemeProvider } from '@mui/material';
// Redux
import { getUser, selectUser, setUser } from './redux/features/userSlice/userSlice';
import { useSelector } from 'react-redux';
import {
  fetchSpaceInfo,
  selectSpaceInfo,
  setSpaceInfo,
  setSpaceFetchStatus,
  setSpaceUrls,
} from './redux/features/spaceSlice/spaceSlice';
import {
  selectedTheme,
  toggleTheme,
  selectThemeToggleStatus,
} from './redux/features/themeSlice';
// Components/ui
import { PageLoading } from './components';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
interface Props {
  rootProps?: RootProps;
}

const App = ({ rootProps }: Props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const space = useSelector(selectSpaceInfo);
  const themeRedux = useSelector(selectedTheme);
  const theme = useMemo(() => createTheme(themeRedux), [themeRedux]);
  const themeToggleStatus = useSelector(selectThemeToggleStatus);

  useEffect(() => {
    if (rootProps?.spaceInfo && rootProps?.userInfo) {
      const { spaceInfo, userInfo } = rootProps;
      dispatch(setSpaceInfo(spaceInfo));
      dispatch(setSpaceFetchStatus('succeeded'));
      dispatch(setSpaceUrls(spaceInfo.id));
      dispatch(setUser(userInfo));
    } else {
      dispatch(fetchSpaceInfo());
      dispatch(getUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user !== null) {
      dispatch(toggleTheme(user.theme));
    }
  }, [user, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      {user && space && themeToggleStatus === 'toggled' ? (
        <Router />
      ) : (
        <PageLoading />
      )}
    </ThemeProvider>
  );
};

export default App;
