// Types
import { Storage } from '@mui/icons-material';
import { INavIcon } from '../types/types';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BallotIcon from '@mui/icons-material/Ballot';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';

export const iconsData: INavIcon[] = [
  {
    title: 'global.collection',
    to: '/a/debtcollection/dashboard',
    icon: <Storage />,
  },
  {
    title: 'global.collection',
    to: '/a/debtcollection/agent',
    icon: <AccountBalanceWalletIcon />,
  },
  {
    title: 'global.collection',
    to: '/a/debtcollection/lists',
    icon: <BallotIcon />,
  },
  {
    title: 'global.collection',
    to: '/a/debtcollection/reports',
    icon: <AssessmentIcon />,
  },
  {
    title: 'global.collection',
    to: '/a/debtcollection/system',
    icon: <SettingsIcon />,
  },
];
