import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setSession } from "./sessionSlice/sessionSlice";
import axios from "axios";
import {
  lightBlueIndigo,
  darkBlack,
  darkBlue,
  lightAmber,
  lightBlue,
  lightBrown,
  lightGray,
  lightGreen,
  lightLime,
  lightOrange,
  lightPink,
  lightPurple,
  lightRed,
  lightTeal,
  lightYellow,
} from "../../style/themeProvider";

const THEME_URL = "https://agent-api.trii.app/api/v1/settings/user/setTheme";

const initialState = {
  themeSelected: lightBlueIndigo,
  darkMode: false,
  toggleStatus: "idle",
};

export const updateTheme = createAsyncThunk(
  "Theme/updateTheme",
  async (theme, { dispatch, getState }) => {
    const { uid } = getState().User.user;
    const sessionRefresh = await dispatch(setSession());
    const { jwtToken } = sessionRefresh.payload.idToken;
    const themeJson = JSON.stringify({ theme, uid });
    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };

    const response = await axios.put(THEME_URL, themeJson, config);
    return response.data;
  }
);

export const themeSlice = createSlice({
  name: "Theme",
  initialState,
  reducers: {
    toggleTheme: (state, action) => {
      const theme = getTheme(action.payload);
      state.themeSelected = theme;
      state.toggleStatus = "toggled";
    },
    toggleModeTheme: (state) => {
      state.darkMode = !state.darkMode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTheme.fulfilled, (state, action) => {
      const theme = getTheme(action.payload.theme);
      state.themeSelected = theme;
    });
  },
});
const getTheme = (theme) => {
  switch (theme) {
    case "lightBlueIndigo":
      return lightBlueIndigo;
    case "darkBlack":
      return darkBlack;
    case "darkBlue":
      return darkBlue;
    case "lightAmber":
      return lightAmber;
    case "lightBlue":
      return lightBlue;
    case "lightBrown":
      return lightBrown;
    case "lightGray":
      return lightGray;
    case "lightGreen":
      return lightGreen;
    case "lightLime":
      return lightLime;
    case "lightOrange":
      return lightOrange;
    case "lightPink":
      return lightPink;
    case "lightPurple":
      return lightPurple;
    case "lightRed":
      return lightRed;
    case "lightTeal":
      return lightTeal;
    case "lightYellow":
      return lightYellow;
    default:
      return lightBlueIndigo;
  }
};

export const { toggleTheme, toggleModeTheme } = themeSlice.actions;
export const selectThemeToggleStatus = (state) => state.Theme.toggleStatus;
export const selectedTheme = (state) => state.Theme.themeSelected;
export const selectedDarkTheme = (state) => state.Theme.darkMode;

export default themeSlice.reducer;
