import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { UserAccount } from '../Models/UserAccount';

interface IContextProps {
  pageLoaded: boolean;
  isMobile: boolean;
  setIsMobile: (value: boolean) => void;
  usuario: UserAccount;
  setUsuario: (value: UserAccount) => void;
  userProfile: any;
  setUserProfile: (value: any) => void;
  isEdit: boolean;
  setIsEdit: (value: boolean) => void;
  editAccount: any;
  setEditAccount: (value: any) => void;
  BASE_URL: string;
}

export const DataContext = createContext({} as IContextProps);

const DataProvider = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const dataUsuario = new UserAccount({});
  const [usuario, setUsuario] = useState(dataUsuario);
  const [userProfile, setUserProfile] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editAccount, setEditAccount] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false); //eslint-disable-line
  const BASE_URL = 'https://webapp.trii.app'

  React.useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      setIsMobile(true);
      // console.log(isMobile)
    } else {
      // false for not mobile device
      setIsMobile(false);
      // console.log(isMobile)
    }
  }, [isMobile]);

  return (
    <DataContext.Provider
      value={{
        pageLoaded,
        isMobile,
        setIsMobile,
        usuario,
        setUsuario,
        userProfile,
        setUserProfile,
        isEdit,
        setIsEdit,
        editAccount,
        setEditAccount,
        BASE_URL,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
DataProvider.propTypes = {
  children: PropTypes.node,
};
export default DataProvider;
