export const style = {
  iframe: {
    border: '0',
    left: '48px',
    position: 'absolute' as 'absolute',
    top: '49px',
    width: 'calc(100vw - 56px)',
    height: 'calc(100vh - 73px)',
  },
};
