import { useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Context
import { DataContext } from 'context/contextApi';
// Selector
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
// Components/ui
import { Box } from '@mui/material';
import { style } from './style';

const AgentIFRAME = () => {
  const { BASE_URL } = useContext(DataContext);
  const space = useSelector(selectSpaceInfo);
  const src = `${BASE_URL}/DebtCollection/agentpanel?spaceId=${space.id}`;

  return (
    <Box>
      <div className="col-md-12">
        <div className="emdeb-responsive">
          <iframe
            src={src}
            sandbox="allow-same-origin allow-scripts"
            width="100%"
            frameBorder={0}
            allowTransparency={true}
            style={style.iframe}
          ></iframe>
        </div>
      </div>
    </Box>
  );
};

export default AgentIFRAME;
