import { Routes as ReactRoutes } from 'react-router-dom';
import { Route } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import DashboardIFRAME from 'features/Views/DashboardIFRAME';
import AgentIFRAME from 'features/Views/AgentIFRAME';
import ListsIFRAME from 'features/Views/ListsIFRAME';
import ReportsIFRAME from 'features/Views/ReportsIFRAME';
import SystemIFRAME from 'features/Views/SystemIFRAME';

const Routes = () => {
  return (
    <MainLayout>
      <ReactRoutes>
        <Route
          path="/a/debtcollection/dashboard"
          caseSensitive
          element={<DashboardIFRAME />}
        />
        <Route
          path="/a/debtcollection/agent"
          caseSensitive
          element={<AgentIFRAME />}
        />
        <Route
          path="/a/debtcollection/lists"
          caseSensitive
          element={<ListsIFRAME />}
        />
        <Route
          path="/a/debtcollection/reports"
          caseSensitive
          element={<ReportsIFRAME />}
        />
        <Route
          path="/a/debtcollection/system"
          caseSensitive
          element={<SystemIFRAME />}
        />
      </ReactRoutes>
    </MainLayout>
  );
};

export default Routes;
